import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { serverUrl } from '../../ApiUri';
import Multiselect from 'multiselect-react-dropdown'
import { signUpUser } from '../Redux/Action/AllHouseUser';
import { notifyFailed } from '../utlity/Notification';

function AddUserInHouse({roomData}) {
    const [show, setShow] = useState(false);
    const [rooms,setRooms] = useState([])
    const [loading,setloading] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
     useEffect(()=>{
        setRooms([])
     },[])
    const handleSubmit = (event) => {
        setloading(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let roomId = []
        console.log(rooms);
        for(let r of roomData){
            let find =false
            for(let rr of rooms){
                if(rr._id===r._id){
                 
                   find=true
                }
            }
            if(!find){
                roomId.push(r._id)
            }
        }
     
        let detail = {
            name: data.get('name'),
            phoneNumber: data.get('phoneNumber'),
            date: data.get('date')
        }
        if(roomId.length===0){
            notifyFailed("Room Not Selected")
        }
        let d = { "email": detail.phoneNumber,
        "name":detail.name,
         "houseId": serverUrl.houseId,
         "isMaster": false, 
         "owner": false, 
         "roomIds": roomId, 
         "timed": true, 
         "validtill": detail.date }
        console.log(d, "detal");
        signUpUser(d,setloading,handleClose)
    }
    return (
        <>
            <Button variant="primary" onClick={handleShow} style={{ position: "absolute", top: "2vh", right: "2vw" }}>
                Add User
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Guest Name</Form.Label>
                            <Form.Control type="text" placeholder="name" name='name' required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Guest Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="mobile number" name='phoneNumber' required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>CheckOut Date</Form.Label>
                            <Form.Control type="date" placeholder="date" name="date" required/>
                            </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Room Select</Form.Label>
                        <Multiselect
                    displayValue="roomName"
                    selectedValues={rooms}
                    onRemove={setRooms}
                    onSelect={setRooms}
                    
                    options={roomData}
                    style={{
                      
                        multiselectContainer: {
                            marginTop: "3px",
                            backgroundColor: "#ffffff"
                        },
                    }}
                />
                             </Form.Group>
                        <Button 
                          variant="primary" 
                          type='submit' 
                          disabled={loading}
                        >
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default AddUserInHouse; 