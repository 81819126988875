import axios from "axios";
import { serverUrl } from "../../../ApiUri";
import store from "..";
import { UserList } from "./ActionTypes";
import { notifyFailed, notifySuccess } from "../../utlity/Notification";

const loadUsers = (data) => {
    return {
        type: UserList.LOAD_USERS,
        payload: data
    }
}

export const updateSelectedHouse = (houseID, email) => {
    if (!String(email).startsWith('+91')){
        email=`+91${email}`
    }
      axios
        .post(
          `${serverUrl.main}/api/update/selectedHouse?user=${
            serverUrl.user
          }`,
          {
            email: email,
            houseAccessCode: serverUrl.houseId,
          },
        )
        .then(res => {
        
        })
        .catch(error => {
        //   resolve({success: false, message: 'Update failed'});
        });

  };

export const fetchHouseUserDetails =
    () => {



        axios.post(
            `${serverUrl.main}/v2/house/user_details?user=${serverUrl.user
            }&timestamp=${new Date().getTime()}`,
            {
                houseId: serverUrl.houseId,
                userId: serverUrl.user,
            },
        )
            .then(
                result => {
                    const { success, data, message } = result.data;
                    if (success) {
                        store.dispatch(loadUsers(data))
                    } else {
                        store.dispatch(loadUsers({}))
                    }

                },
                error => {
                    let message = error.message;
                    if (error.response && error.response.data) {
                        message = error.response.data.message;
                    }

                    store.dispatch(loadUsers({}))
                },
            )
            .catch(error => {

                store.dispatch(loadUsers({}))
            });

    };
export const shareHouse = async(details,handleClose) => {
       
        return await axios.post(
            `${serverUrl.main}/v2/house/add/userWithAccess?user=${serverUrl.user
            }&time=${new Date().getTime()}`,
            details,
        )
            .then(
                result => {
                    const { data, success, message } = result.data;
                    if (success) {
                        notifySuccess(message)
                        fetchHouseUserDetails()
                        handleClose()
                        updateSelectedHouse(details.houseID,details.email)
                        return true
                    } else {
                        notifyFailed(message)
                        return false

                    }
                 
                }
             
            )
            .catch(error => {
                notifyFailed(error.response.data.message)
                return false
            });
    
    
    };
export const signUpUser = async(detail, setloading,handleClose) => {
    axios.post(`${serverUrl.main}/v2/auth/directSignup`, {
        "phone": detail.email,
        "name": detail.name
    }).then(async function (res) {
        if (res.data.success) {

             await shareHouse(detail)
           
        }else{
           if(res.data.message==="Number already registered"){
            await shareHouse(detail,handleClose)
           }else{
            notifyFailed(res.data.message)
           }
        }
        setloading(false)
    }).catch(function (err) {
        setloading(false)
    })
}

export const removeUserFromHouse =
  (userId, houseId) =>{

 
      axios.put(
        `${serverUrl.main}/v2/house/remove_user_from_house?user=${
          serverUrl.user
        }&timestamp=${new Date().getTime()}`,
        {
          userId,
          houseId,
        },
      )
        .then(
          result => {
            const {success, data, message} = result.data;
             if(success){
                notifySuccess(message)
                fetchHouseUserDetails()
             }else{
                notifyFailed(message)
             }
          }
        )
        .catch(error => {
          notifyFailed(error.response.data.message)
        });

  };

  