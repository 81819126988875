import { createStore,combineReducers } from "redux";
import { PropertyDetails,HouseDetails } from "./Reducers/PropertyReducer";
import { userDetailReducer } from "./Reducers/UserReducer";
import {persistStore,persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage'
import { toadayLogReducer } from "./Reducers/logsReducer";
import { fetchScheduleReducer } from "./Reducers/ScheduleReducer";
import { storeAutoCut } from "./Reducers/AutoCut";
import { houseUsersList } from "./Reducers/AllHouseUser";

const persistConfig = {
    key: 'persist-key',
    storage
}

const persistedReducer = persistReducer(persistConfig,combineReducers({
    PropertyDetails,
    HouseDetails,
    User:userDetailReducer,
    todayLogs:toadayLogReducer,
    schedule:fetchScheduleReducer,
    autoCut:storeAutoCut,
    users:houseUsersList,
}))

let store = createStore(persistedReducer);
let persistor = persistStore(store)
export default store;
export {persistor}