import React, { useEffect, useState } from 'react'
import SideBar from '../Common/SideBar'
import Header from '../Common/Header'
import { Button, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import AddUserInHouse from '../ShareHouse/AddUserInHouse'
import { Trash } from 'react-bootstrap-icons';
import { fetchHouseUserDetails, removeUserFromHouse } from '../Redux/Action/AllHouseUser'
import { serverUrl } from '../../ApiUri'
import './DashBoard.css'

function ShareHouse() {
    const users = useSelector(state => state.users)
    let HouseDetails = useSelector(state => state.HouseDetails)
    let [data, setData] = useState([])
    console.log(users, "user");
    useEffect(() => {

        let rooms = []
        if (HouseDetails.length === 0) return
        for (let house of HouseDetails) {
            for (let r of house.rooms) {
                rooms.push(r)
            }
        }
        setData(rooms)
        fetchHouseUserDetails()
    }, [HouseDetails])
    //     const findTimeUser=(email)=>{
    //          for(let u of users.timed){
    //             if(u.email===email){
    //                 return u.validtill
    //             }
    //          }
    //          return "------"
    //     }
    //     const roomAccess=(email)=>{
    //         let roomNames=[]
    //         let totalRoomId = []
    //         let roomIdAccess = []

    //         if(users.roomAccess[email]!==undefined){

    //             for(let r of data){
    //                 totalRoomId.push(r._id)
    //             }
    //             for(let tr of totalRoomId){
    //                if(!users.roomAccess[email].includes(tr)){
    //                   roomIdAccess.push(tr)
    //                }

    //             }

    //             // for(let roomid of users.roomAccess[email]){
    //             // let find = false
    //             // for(let r of data){

    //             //     if(r._id===roomid){
    //             //         find=true
    //             //     }
    //             //     if(!find){
    //             //         console.log(r.roomName,r._id,email,"room");
    //             //       if(!roomIdAccess.includes(roomid)){

    //             //       roomIdAccess.push(roomid)
    //             //       }
    //             //     }
    //             // }

    //             // }
    //         }

    //         for(let d of data){
    //             let find = false
    //             for(let r of roomIdAccess){
    //             if(r===d._id){
    //                 roomNames.push(d.roomName)
    //             }
    //         }
    //         // if(!find){
    //         //     roomNames.push(d.roomName)
    //         // }
    //         }


    //         return roomNames
    //    }
    const findAccessUser = (user, room) => {
        let find = undefined
        if (users.roomAccess[user.email] !== undefined) {
            find = users.roomAccess[user.email].find(id => room._id === id)
        }
        if (find === undefined) {
            let time = users.timed.filter(t => t.email === user.email)
            let validated = ""
            if (time.length > 0) {
                validated = time[0].validtill
            }
            return { name: user.name, validtill: validated }
        }
    }
    return (
        <div style={{ display: "flex" }}>
            <SideBar />
            <div>
                <Header />
                <div
                    style={{ overflow: "scroll", backgroundColor: "#F9F9FB", position: "relative" }}
                    className='dashboard width'
                >
                    <AddUserInHouse roomData={data} />
                    {/* <div style={{width:"91vw",marginLeft:"2vw",marginTop:"10vh",height:"78vh",overflowY:"scroll"}} className='scrollBarRemove'>
                    {
                        Object.keys(users).length!==0 && (
                            <Table striped bordered hover variant="light">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                  
                                    <th>Username</th>
                                    <th>Access Till</th>
                                    <th>Room Access</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                            users.users.map((user,index)=>{
                                return(
                                    <tr key={user._id}>
                                    <td>{index+1}</td>
                                    <td>{user.name}</td>
                                    
                                    <td>{user.email}</td>
                                    <td>{findTimeUser(user.email)}</td>
                                    <td>
                                        <ul>
                                        {roomAccess(user.email).map(e=>(
                                            <li>{e}</li>
                                        ))}
                                        </ul>
                                    </td>
                                    <td
                                      onClick={()=>removeUserFromHouse(user.email,serverUrl.houseId)}
                                    ><Trash/></td>
                                </tr>
                                )
                            })
                                }
                               
                             
                            </tbody>
                        </Table>
                        )
                    }
                   
                    </div> */}

                    <div style={{ width: "96vw", marginLeft: "2vw", marginTop: "10vh", height: "78vh", overflowY: "scroll" }} className='scrollBarRemove'>
                        {
                            Object.keys(users).length !== 0 && (
                                <Table striped bordered hover variant="light">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Room Name</th>
                                            <th>Room Access Valid Till</th>
                                            {/* <th>Username</th>
                                    <th>Access Till</th>
                                    <th>Room Access</th>
                                    <th>Delete</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((room, index) => {
                                                return (
                                                    <tr key={room._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{room.roomName}</td>
                                                        <td><ul>
                                                            {
                                                                users.users.map(user => {
                                                                    let userD = findAccessUser(user, room)
                                                                    console.log(userD, 'user');
                                                                    if (userD) {
                                                                        return (
                                                                            <li>{userD.name} - {userD.validtill}</li>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </ul></td>
                                                        {/* <td>{user.email}</td>
                                    <td>{findTimeUser(user.email)}</td>
                                    <td>
                                        <ul>
                                        {roomAccess(user.email).map(e=>(
                                            <li>{e}</li>
                                        ))}
                                        </ul>
                                    </td>
                                    <td
                                      onClick={()=>removeUserFromHouse(user.email,serverUrl.houseId)}
                                    ><Trash/></td> */}
                                                    </tr>
                                                )
                                            })
                                        }


                                    </tbody>
                                </Table>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareHouse