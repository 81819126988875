import axios from 'axios'
import React from 'react'
import {Wifi,WifiOff} from 'react-bootstrap-icons'
import { serverUrl } from '../../ApiUri'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button } from 'react-bootstrap'

export default function DeviceOnLIneOffLine({deviceId,mqt,switchId}) {
    let [online,setOnline] = useState("")
    let [onTime,setOnTime] = useState("")
    // const deviceCheck = ()=>{
    //     if(mqt===false){
    //     axios.get(`${serverUrl.a3}/v2/admin/deviceOnline/${deviceId}`).then(function (res) {
    //         if(res.status===200){
    //             setOnline(res.data.status)
    //         }
    //     }).catch(function (error) {
            
    //         setOnline('offline')
    //     })
    // }else{
    //     axios.post(`${serverUrl.mqt}/connectionStatus`,{
    //         deviceId:deviceId
    //     }).then(function (res) {
    //         if(res.data.success===true){
    //             console.log(res,"mqt wifi");
    //             if(res.data.data.connected===true){
    //                 setOnline("online") 
                    
    //             }else{
    //                 setOnline("offline")
    //             }
    //         }else{
    //             setOnline("offline")
    //         }
    //     }).catch(function (error) {
            
    //         setOnline('offline')
    //     })
    // }
    // }
    const todayLogOntime = ()=>{
        setOnline("")
        axios.post("https://analytics.alistetechnologies.com:443/analytic/v2/getDataForToday", {
            deviceId: deviceId,
            switchId: switchId,

        }).then(function (res) {
            const data = res.data.results.finalResult;
            if (data.ontime!==undefined) {
                const durationInMilliseconds = data.ontime;
     
                const seconds = Math.floor(durationInMilliseconds / 1000);
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const minutess = Math.floor((seconds % 3600));
                const remainingSeconds = seconds % 60;
                
                const formattedTime = `${hours}h ${minutes.toString().padStart(2, '0')}m `;
                setOnTime(`${formattedTime}`)
                if(hours>=1){
                     console.log(minutess,"online");
                  setOnline("online")
                }else{
                    // console.log(new Date(data.ontime).getMinutes(),"offline");
                    setOnline("offline")
                }
            } else {
                setOnline("offline")
            }

        }).catch(function (err) {
            ////console.log(err, "error");
            setOnline("offline")
        })
    }
    useEffect(()=>{todayLogOntime()},[])
  return (
    <>
    <td className='tableData'>
    {
       online==="online"? <Button variant="outline-success" size='sm' style={{ backgroundColor: "rgba(115, 241, 191, 0.3)" }}>Occupied</Button>:(online===""?"Loading...":<Button size='sm' style={{ backgroundColor: "rgba(189, 0, 0, 0.3)" }} variant="outline-danger">UnOccupied</Button>)
    }
    </td>
    <td className='tableData'>{onTime}</td>
    </>
  )
}
