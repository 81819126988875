import { type } from "@testing-library/user-event/dist/type"
import { UserList } from "../Action/ActionTypes"


export const houseUsersList= (state={},action)=>{
   const {type,payload} = action
   switch(type){
       case UserList.LOAD_USERS:
           return payload
       default:
           return state
   }
}