import React from 'react'
import { Link } from 'react-router-dom'
import './SideBar.css'
export default function SideBar() {
  return (
    <div className='siderBarContaoner'>
       <div style={{height:"10vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <img style={{height:"42px",width:"42px"}} src={process.env.PUBLIC_URL+'./logo.jpeg'}/>
       </div>
       <Link to="/dashboard" style={{ color: 'inherit', textDecoration: 'inherit'}}>
       <div style={{height:"10vh",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
            <img style={{height:"25.11px",width:"28.83px"}} src={process.env.PUBLIC_URL+'./assets/Img/Home.png'}/>
            <span style={{fontSize: "8.76585px",lineHeight: "13px"}}>Home</span>
       </div>
       </Link>
       <Link to="/shareHouse" style={{ color: 'inherit', textDecoration: 'inherit'}}>
       <div style={{height:"10vh",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",textDecoration:"none"}}>
            <img style={{height:"25.11px",width:"28.83px"}} src={process.env.PUBLIC_URL+'./assets/Img/shareHouse.png'}/>
            <span style={{fontSize: "8.76585px",lineHeight: "13px",textDecoration:"none"}}>Share Access</span>
       </div>
       </Link>
    </div>
  )
}
