import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import WifiSessions from './WifiSessions';
import { serverUrl } from '../../ApiUri';

export default function LogModalOnTime(props) {
    let [logs, setLogs] = useState([])
    let [message, setMessage] = useState('')
    const { logSwitch, show } = props


    // useEffect(() => {
    //     if (show === false) return
    //     setMessage("")
    //     setLogs([])
    //     axios.post('https://analytics.alistetechnologies.com:443/analytic/v2/geSnapShotData',
    //      {
    //         dSIDs: [`${logSwitch.deviceId}$${logSwitch.switchId}`],
    //         date:new Date(),
    //         firstDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    //         lastDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    //     }
    //     ).then(function (res) {

    //         if (res.data.result === "success") {
    //             setLogs(res.data.data)
    //         } else {
    //             setMessage("Data Not Found")
    //         }
    //     }).catch(function (err) {
    //         console.log(err);
    //         setMessage("Data Not Found")
    //     })
    // }, [logSwitch])
    useEffect(() => {
        if (show === false) return
        setMessage("")
        setLogs([])
        axios.post('https://analytics.alistetechnologies.com:443/analytic/v2/getDataForDate',
        {
            "odate" : new Date(new Date().setDate(new Date().getDate() - 1)),
            "deviceId": logSwitch.deviceId,
            "switchId":logSwitch.switchId,
            "time" : 6,
            "wifiPercentageHold": 12,
            "onThreshold" :4,
            "houseID": serverUrl.houseId,
            "avgValue" : 0,
            "shouldCalculate" : false ,
            "shouldCalculateBillDifference" : false
            }
        ).then(function (res) {

            if (res.data.company === "success") {
                setLogs([res.data.results.finalResult])
            } else {
                setMessage("Data Not Found")
            }
        }).catch(function (err) {
            console.log(err);
            setMessage("Data Not Found")
        })
    }, [logSwitch])

    const calculate = (date) => {
        console.log(date,"fffff");
        let milliseconds = date
        const seconds = Math.floor((milliseconds / 1000) % 60);

        const minutes = Math.floor((milliseconds / 1000 / 60) % 60);

        const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

        const formattedTime = [
            hours.toString().padStart(2, "0"),
            minutes.toString().padStart(2, "0"),
            seconds.toString().padStart(2, "0")
        ];

        return `${hours.toString().padStart(2, "0")}h ${minutes.toString().padStart(2, '0')}m `;
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>
                <div style={{ textAlign: "right" }}>
                    <span
                        onClick={() => {
                            props.onHide()
                            setLogs([])
                            setMessage("")
                        }}
                        style={{ fontSize: "18px", cursor: "pointer" }}
                    >X</span>
                </div>
                {
                    (logs.length !== 0) ?
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Device Online</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logs && logs.map((date, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i+1}</td>
                                                    <td>{moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("DD-MMM-YYYY")}</td>
                                                    <td>{
                                                        calculate(date?.wifiOnTime!==undefined?date.wifiOnTime:0)
                                                        }
                                                    </td>
                                                    <td>
                                                        <WifiSessions wifiTime={date?.wifiSessions!==undefined?date?.wifiSessions:[]}/>
                                                    </td>
                                                </tr>
                                            )
                                        
                                    })
                                }
                            </tbody>
                        </Table> : <h5>{message === "" ? "Loading..." : message}</h5>
                }


            </Modal.Body>

        </Modal>
    );
}
