import axios from "axios"
import { serverUrl } from "../../../ApiUri"
import { getProperties, house, switchState } from "./ActionTypes"
import { notifySuccess } from "../../utlity/Notification"
import { fetchSingleSchedule } from "./ScheduleAction"
import store from "../index"
import { fetchAutoCut, getAutoCut } from "./AutoCut"

const getProperty = (data)=>{
    return{
        type:getProperties.GET_ALL_PROPERTY,
        payload:data
    }
}
export const emptyProperty = (data)=>{
    return{
        type:getProperties.EMPTY_PROPERTIES,
        payload:data
    }
}
export const updateProperty =(data)=>{
  return{
    type:getProperties.UPDATE_PROPERTIES,
    payload:data
  }
}
const houseData = (data)=>{
    return{
        type:house.FETCH_HOUSE,
        payload:data
    }
}
const allHouseData = (data)=>{
    return{
        type:house.FETCH_HOUSE,
        payload:data
    }
}
export const houseDataEmpty = (data)=>{
    return{
        type:house.EMPTY_HOUSE,
        payload:data
    }
}
export const houseUpdate = (data)=>{
  console.log("call house update");
  return{
    type:house.UPDATE_HOUSE,
    payload:data
  }
}

export const TurnOn = (data)=>{
    return{
        type:switchState.SWITCH_ON,
        payload:data
    }
}
export const TurnOff = (data)=>{
    return{
        type:switchState.SWITCH_ON,
        payload:data
    }
}






export const fetchAllHouse = async(dispatch)=>{
    let house =[]
    let houseids =[]
   
         houseids.push({houseId:"63e0d820ec3cc19b0876d189",city:"Delhi"})
       let obj={}
     await axios
        .get(
          `${serverUrl.main}/api/fetch/house2/${serverUrl.houseId}/${
           serverUrl.user
          }?user=${"Tim-Dasboard"}`,
        ).then(async function (res) {
            
            if(res.status===200){
                obj ={success:true,message:"House Fetch",rooms:res.data.rooms,propertyName:res.data.houseName,house:res.data,city:"Delhi",id:"63e0d820ec3cc19b0876d189"}
                house.push(obj)
            }
        }).catch(function (error) {
            console.log(error);
    
        })
      dispatch(allHouseData(house))
      
}

export const fetchHouseSingle = async(houseid,propertyName,city)=>{
  await axios
  .get(
    `${serverUrl.main}/api/fetch/house2/${houseid}/${
     "+919753124680"
    }?user=${"+919753124680"}`,
  ).then(function (res) {
      
      if(res.status===200){
         let obj ={success:true,message:"House Fetch",rooms:res.data.rooms,propertyName:propertyName,house:res.data,city:city,id:houseid}
         
         store.dispatch(houseUpdate(obj))
      
      }
  }).catch(function (error) {
      console.log(error);

  })
}

const sendSocketMessage = (deviceIds, event, payload) => {
    let deviceType="";
        if(deviceIds.substring(1,3)=="08"){
           deviceType="nova"
        }else{
          deviceType="sync"
        }
	return new Promise(resolve => {
    axios
      .post(`https://a3.alistetechnologies.com/v2/admin/socket_message?invoker=stanzaDashBoard`, {
        deviceType,
        deviceIds:[deviceIds],
        event,
        payload,
      })
      .then(
        result => {
          resolve({success: true, data: result.data, message: ''});
        },
        error => {
          let message = error.message;
          resolve({success: false, message});
        },
      )
      .catch(error => {
        resolve({success: false, message: String(error)});
      });
  });
}
const sendMQTMessage = (deviceIds, on,switchId) => {
  let deviceType="";
      if(deviceIds.substring(1,3)=="08"){
         deviceType="nova"
      }else{
        deviceType="sync"
      }
return new Promise(resolve => {
  axios
    .post(`${serverUrl.mqt}/control`, {
      
      "deviceId": deviceIds,
      "switchId": switchId,
      "command": on,
     "id": String(new Date().getTime()).slice(5,13),
     "controllerType": "portal",
     "controllerId": "stanza",
     "controllerDetails": "stanza"
    })
    .then(
      result => {
        resolve({success: true, data: result.data, message: ''});
      },
      error => {
        let message = error.message;
        resolve({success: false, message});
      },
    )
    .catch(error => {
      resolve({success: false, message: String(error)});
    });
});
}  

export const TurnOnApi = (dispatch,sswitch,devicesId,houseId,mqt)=>{
     
     let newSwitch = {...sswitch,switchState:"1"}
     dispatch(TurnOn({switch:newSwitch,devicesId:devicesId,houseId:houseId}))
     if(mqt===false){
     sendSocketMessage(devicesId,"chat message",`${sswitch.switchId},${1},${String(new Date().getTime()).slice(5, 13)}`)
     }else{
      sendMQTMessage(devicesId,"100",sswitch.switchId)
     }
}

export const TurnOffApi = (dispatch,sswitch,devicesId,houseId,mqt)=>{
    
    let newSwitch = {...sswitch,switchState:"0"}
    dispatch(TurnOn({switch:newSwitch,devicesId:devicesId,houseId:houseId}))
    if(mqt===false){
    sendSocketMessage(devicesId,"chat message",`${sswitch.switchId},${0},${String(new Date().getTime()).slice(5, 13)}`)
    }else{
      sendMQTMessage(devicesId,"0",sswitch.switchId)
     }

}

export const TurnOnAllApi = (dispatch,selectedApllliances,setProductDataItem)=>{
    
    for(let swit of selectedApllliances){
        let newSwitch ={...swit,switchState:"1"}
        dispatch(TurnOn({switch:newSwitch,devicesId:newSwitch.deviceId,houseId:swit.houseId}))
        if(swit.mqt===false){
        sendSocketMessage(newSwitch.deviceId,"chat message",`${newSwitch.switchId},${1},${String(new Date().getTime()).slice(5, 13)}`)
        }else{
          sendMQTMessage(newSwitch.deviceId,"100",newSwitch.switchId)
        }
    }
    setProductDataItem([])

}

export const TurnOffAllApi = (dispatch,selectedApllliances,setProductDataItem)=>{
    
    for(let swit of selectedApllliances){
        let newSwitch ={...swit,switchState:"0"}
        dispatch(TurnOff({switch:newSwitch,devicesId:newSwitch.deviceId,houseId:swit.houseId}))
        if(swit.mqt===false){
        sendSocketMessage(newSwitch.deviceId,"chat message",`${newSwitch.switchId},${0},${String(new Date().getTime()).slice(5, 13)}`)
        }else{
          sendMQTMessage(newSwitch.deviceId,"0",newSwitch.switchId)
        }
    }
    setProductDataItem([])
}

export const SelctedSwitchMannualBlock=async(productDataItem,setProductDataItem,user,event)=>{
    
    for(let swit of productDataItem){
        let deviceType="";
        if(swit.deviceId.substring(1,3)=="08"){
           deviceType="nova"
        }else{
          deviceType="sync"
        }
        await axios.post(`https://a3.alistetechnologies.com/v2/admin/socket_message?user=${user.username}&username=${user.username}&timestamp=${new Date()}`,{
              deviceType: deviceType,
              deviceIds: [swit.deviceId],
              event: 'switch_pins',
              payload: `${swit.switchId},${event},${String(new Date().getTime()).slice(5,13)}`,
             }).then(function (res) {
                
             }).catch(function (err) {
                console.log(err);
             })
    }
    if(event===0){
      notifySuccess("Switches Blocked Sucessfully")
    }else if(event===1){
      notifySuccess("Switches UnBlocked Sucessfully") 
    }
    setProductDataItem([])
   
}

export const deviceBlock = async(productDataItem,setProductDataItem,user,decision,houseId,dispatch,citys,selectedCity,property)=>{
       for(let item of productDataItem){
        let event = ""
        if(decision===1){
            if(item.deviceId.substring(1,3)=="01"){
              event="1"
           }else if(item.deviceId.substring(1,3)=="02"){
             event="11"
           }else if(item.deviceId.substring(1,3)=="03"){
             event="1111"
           }else if(item.deviceId.substring(1,3)=="04"){
             event="11111"
           }else if(item.deviceId.substring(1,3)=="05"){
             event="11111"
           }else if(item.deviceId.substring(1,3)=="06"){
             event="11"
           }else if(item.deviceId.substring(1,3)=="12"){
             event="111"
           }else if(item.deviceId.substring(1,3)=="13"){
             event="1111"
           }else{
            continue;
           }
          }else if(decision===0){
            if(item.deviceId.substring(1,3)=="01"){
              event="0"
           }else if(item.deviceId.substring(1,3)=="02"){
             event="00"
           }else if(item.deviceId.substring(1,3)=="03"){
             event="0000"
           }else if(item.deviceId.substring(1,3)=="04"){
             event="00000"
           }else if(item.deviceId.substring(1,3)=="05"){
             event="00000"
           }else if(item.deviceId.substring(1,3)=="06"){
             event="00"
           }else if(item.deviceId.substring(1,3)=="12"){
             event="000"
           }else if(item.deviceId.substring(1,3)=="13"){
             event="0000"
           }else{
            continue;
           }
          }
          axios.post(`${serverUrl.a3}/v3/device/updateSwitchStates?user=+919753124680`,{
            deviceId: item.deviceId,
            states: event
          }).then(function (res) {
              
          }).catch(function (error) {
            console.log(error);
          })
       }
       if(decision===0){
        notifySuccess("Switches Bloacked Sucessfully")
        fetchAllHouse(property,dispatch,selectedCity===""?citys[0]:selectedCity)
        }else if(decision===1){
          notifySuccess("Switches UnBloacked Sucessfully")
            fetchAllHouse(property,dispatch,selectedCity===""?citys[0]:selectedCity)
        }
        setProductDataItem([])
}

export const findHoseDetails = (deviceId,switchId,roomId)=>{
  console.log(store.getState().HouseDetails,deviceId,switchId,roomId)
  for(let house of store.getState().HouseDetails){
    for(let room of house.rooms){
        if(room._id===roomId){
          for(let device of room.devices){
            if(device.deviceId===deviceId){
              for(let switchs of device.switches){
                if(switchs.switchId===switchId){
                  console.log(switchs,device,room,"house");
                  return [switchs.switchName,room.roomName,house.propertyName]
                }
              }
            }
          }
        }
    }
  }
}