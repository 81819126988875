import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'

function WifiSessions({wifiTime}) {
    let [open,setOpen] = useState(false)
    console.log(wifiTime,"time");
    let [logData,setLogData] = useState([])
    useEffect(()=>{
        if(!open) return
        let data = []
        for(let d of wifiTime){
            data.push(d[1])

        }
        console.log(data,"fgg");
        setLogData(data)
    },[open])
    return (
    <>
      <Button
        onClick={()=>setOpen(true)}
      >Detail</Button>

      <Modal
            show={open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>
                <div style={{ textAlign: "right" }}>
                    <span
                        onClick={() => {
                            setOpen(false)
                        }}
                        style={{ fontSize: "18px", cursor: "pointer" }}
                    >X</span>
                </div>
                {
                    (wifiTime.length !== 0) ?
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Wifi Connection Start</th>
                                    <th>Wifi Connection End</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logData.map((e, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i+1}</td>
                                                    <td>{e.realConnectedTime==="Invalid Date"?"":e.realConnectedTime}</td>
                                                    <td>{e.realDisconnectedTime==="Invalid Date"?"":e.realDisconnectedTime}</td>
                                                </tr>
                                            )
                                        
                                    })
                                }
                            </tbody>
                        </Table> : <h5>{"Sessions Not Available"}</h5>
                }


            </Modal.Body>

        </Modal>
    </>
  )
}

export default WifiSessions