import React, { useEffect } from 'react'
import "./Header.css"
import { useSelector } from 'react-redux'
import  { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
export default function Header() {
  const user = useSelector(state=>state.User)
  const navigate = useNavigate()
  useEffect(()=>{
    if(Object.keys(user).length===0){
         navigate("/")
    }
  },[user])
  return (
    <div 
      style={{
        width:"100%",
        height:"10vh",
        backgroundColor:"#FFFFFF",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        padding:"0px 5%"
      }}
    >
      <Toaster position="top-center" />
        <div>
            <span className='clientHeader'>TIM LEISURE </span>
            <span className='clientSubHeader'>SERVICES</span>
        </div>
        <div style={{
            display:"flex"
        }}>
           
            <div
             style={{
                display:"flex",
                flexDirection:"row",
                marginLeft:"10px",
                justifyContent: "center",
                gap:"10px"
             }}
            >
                <span className='userName'>{user.userName}</span>
                <span className='userName'>v 1.00</span>
                {/* <span className='admin'>admin</span> */}
            </div>
        </div>

    </div>
  )
}
